.home {
  width: 100%;
  height: 100vh;
  /* justify-content: center; */
  /* align-items: center; */
  /* display: flex; */
  justify-content: center;
  align-items: left;
  /* display: none; */
  /* background-color: black; */
  flex-direction: column;
  /* background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;

}

.headerContainer {
  width: auto;
  height: 97%;
  /* margin-left: 50px; */
  margin-bottom: 100px;
  /* margin-left: 0px; */
  border-radius: 10px;
  padding: 10px;
  /* width: 80%; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* background-color: rgba(0,0,0,.4); */
  /* color: white; */
}

.headerContainer h1 {
  font-size: 40px;
  height: 30px;
  /* color: white; */
}

.headerContainer p {
  font-size: 20px;
  color: white;
}


/* .headerContainer button {

  background-color: #121619;
    list-style: none;
    margin: 0 15px;
    
}

.headerContainer button svg {
  position: relative; 
  display: block;
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 63px;
  background: #333;
  border-radius: 50%;
  font-size: 30px;
  color: #666;
  transition: .5s;
} */

.headerContainer button {
  margin-top: 40px;
  width: 140px;
  height: 40px;
  border: none;
  /* appearance: unset; */
  /* text-rendering: unset; */
  background-color: #1976D2;
  color: whitesmoke;
  font-size: 15px;
  cursor: pointer;
}

.headerContainer button:hover {
  /* color: rgba(0, 0, 0, 0.3); */
  transition: 0.1s ease-in-out;
  cursor: pointer;
  transform: scale(1.025); 
}




  /* .image_wrapper {
    margin: 0px 30px;
  }
  
  .image_wrapper img {
    width: 100px;
  } */


/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

@media only screen and (max-width: 400px) {
  .home {
    /* grid-template-columns: 1fr; */
    width: 100%;
    height: 55vh;
  }
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
