.Bottom {
    width: 100%;
    /* height: auto; */
    height: 100%;
    display: flex;
    /* margin: 3%; */
    flex-direction: column;
    z-index: 0;
    /* background: url("../assets/fabricBackground.jpg") repeat fixed 100%; */
    /* background-color: black; */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* padding: 15%; */
    align-items: center;
  }

  .BottomLeft {
    display: flex;
    width: 50%;
    /* background-color: rgba(0,0,0,.3); */
    flex-direction: column;
    height: 55%;
    align-items: center;
    margin: 3%;
    padding: 3%;
  }
  
  .BottomRight {
    width: 70%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: left;
    padding: 60px;
  }
  
  .Bottom h1 {
    font-family: geneva;
  }

  .Bottom h3{
    border-style: solid;
    border-color: #ffd166;
    border-width: 2px;
    padding: 10px;
    text-decoration: none;

  }
  .Bottom a {
    text-decoration: none;
    color: #ffd166;
  }
  
  /* .aboutWelcome h2 {
    color: crimson;
  } */
  
  /* @media only screen and (max-width: 850px) {
    .aboutWelcome {
      flex-direction: column;
    }
    .aboutWelcomeLeft {
      width: 87%;
    }
  } */
  