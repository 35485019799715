.aboutWelcome {
    width: 100%;
    /* height: auto; */
    height: 100%;
    display: flex;
    /* margin: 3%; */
    flex-direction: column;
    z-index: 0;
    /* background: url("../assets/fabricBackground.jpg") repeat fixed 100%; */
    /* background-color: black; */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* padding: 15%; */
    align-items: center;
  }
.aboutWelcomeNav {
  display: flex;
  width: 80%;
  justify-content: space-between;
  color: #f48c06;
  flex-direction: row;
  padding: 0% 10% 30% 10%;
  /* margin: 0% 10% 30% 10%; */
  text-decoration: none;
}  
.aboutWelcomeNav a {
  /* display: flex; */
  /* width: 100%; */
  /* justify-content: space-between; */
  color: #f48c06;
  /* flex-direction: row;
  padding: 0 10% 30% 10%; */
  /* display: block; */
  /* text-decoration: none; */
}  
  .aboutWelcomeLeft {
    display: flex;
    width: 50%;
    /* background-color: rgba(0,0,0,.3); */
    flex-direction: column;
    height: 55%;
    align-items: center;
    margin: 3%;
    padding: 3%;
  }
  
  .aboutWelcomeRight {
    width: 70%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: left;
    padding: 60px;
  }
  
  .aboutWelcome h1 {
    font-family: geneva;
  }
  .aboutWelcomeNav2 {
    /* display: none; */
  }
  /* .aboutWelcome h2 {
    color: crimson;
  } */
  
  @media only screen and (max-width: 850px) {
    .aboutWelcomeNav {
      display:none;
    }
    .aboutWelcomeNav2 {
      /* display: ; */
      /* z-index: -6; */
    }
    .aboutWelcomeLeft {
      width: 87%;
    }
  }
  