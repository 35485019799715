/* @import "~react-image-gallery/styles/css/image-gallery.css"; */

.about {
  /* width: 94%; */
  /* width */
  /* height: auto; */
  /* height: 900px; */
  display: flex;
  /* margin: 3%; */
  /* color: white; */
  /* flex-direction: row; */
  flex-direction: column;
  /* background-color: rgba(0,0,0,.5); */
  /* background-color: black; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* align-items: center; */
}

.aboutLeft {
  display: flex;
  width: 88%;
  /* background-color: rgba(0,0,0,.3); */
  flex-direction: column;
  /* height: 55%; */
  align-items: center;
  margin: 3%;
  padding: 3%;
}

.aboutRight {
  width: 94%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  padding: 3%;
}

.about h1 {
  font-family: geneva;
}

.about p {
  font-size:20;
  font-family: 'Rubix';
  font-style: 'italic';
}

/* 
@media only screen and (max-width: 850px) {
  .about {
    flex-direction: column;
  }
  .aboutLeft {
    width: 87%;
  }
  .aboutRight {
    width: 87%;
  }
} */
