.footer {
  width: 100%;
  height: 200px;
  /* background-color: #121619; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 10px;
  z-index: 20;
}

.socialMedia svg {
  /* color: white; */
  margin: 20px;
  font-size: 70px;
  cursor: pointer;
}

.socialMedia svg:hover {
  /* color: rgba(0, 0, 0, 0.3); */
  transition: 0.1s ease-in-out;
  cursor: pointer;
  transform: scale(1.025); 
}

.footer p {
  /* color: white; */
}
