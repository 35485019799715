@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */

/* body {
  font-family: "Poppins", sans-serif;
} */

.active2{
  height: 4rem;
  width: 100%;
  position: fixed;
  top: 0px;
  transition: 0.3s linear;
  display: flex;
  justify-content:stretch;
  align-items: center;
  /* background-color: #000; */
  /* border-bottom: 1px solid rgba(0, 0, 0, .1); */
  z-index: -40;
  /* box-shadow: 0 2px 5px -1px rgba(0, 0, 0, .08); */
  /* padding: 0 7%; */
}

.hidden2{
  height: 4rem;
  width: 100%;
  z-index: -40;
  border-bottom: 1px solid rgba(0, 0, 0, .1);
  box-shadow: 0 2px 5px -1px rgba(0, 0, 0, .08);
  position: fixed;
  /* top: -80px; */
  top: -160px;
  transition: 0.3s linear;

}


.container {
  max-width: 1050px;
  width: 90%;
  margin: auto;
}

.navbar2 {
  width: 100%;
  position: fixed;
  /* top: 0; */
  display: none;
  z-index: 6;
  /* transition-timing-function: ease-in; */
  /* transition: 0.5s; */
  box-shadow: 0 1px 4px rgb(146 161 176 / 15%);
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 62px;
}

.navbar2 .menu-items {
  display: flex;
}

.navbar2 .nav-container li {
  list-style: none;
}

.navbar2 .nav-container a {
  text-decoration: none;
  color: #0e2431;
  font-weight: 500;
  font-size: 1.2rem;
  padding: 0.7rem;
}

.navbar2 .nav-container a:hover{
    font-weight: bolder;
}

.nav-container {
  display: block;
  position: relative;
  height: 60px;
}

.nav-container .checkbox {
  position: absolute;
  display: block;
  height: 32px;
  width: 32px;
  top: 20px;
  right: 60px;
  z-index: 5;
  opacity: 0;
  cursor: pointer;
}

.nav-container .hamburger-lines {
  display: block;
  height: 26px;
  width: 32px;
  position: absolute;
  top: 17px;
  right: 60px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.nav-container .hamburger-lines .line {
  display: block;
  height: 4px;
  width: 100%;
  border-radius: 10px;
  /* background: #0e2431; */
  background: #EA5F26;
}

.nav-container .hamburger-lines .line1 {
  transform-origin: 0% 0%;
  transition: transform 0.4s ease-in-out;
}

.nav-container .hamburger-lines .line2 {
  transition: transform 0.2s ease-in-out;
}

.nav-container .hamburger-lines .line3 {
  transform-origin: 0% 100%;
  transition: transform 0.4s ease-in-out;
}

.navbar2 .menu-items {
  padding-top: 120px;
  box-shadow: inset 0 0 2000px #772f1a;
  /* background-color: rgba(255, 255, 255, .7); */
  background: #cc5803;
  /* background-color: rgba(255, 255, 255, .9); */
  height: 100vh;
  width: 100%;
  transform: translate(-150%);
  display: flex;
  flex-direction: column;
  margin-left: -40px;
  padding-left: 50px;
  transition: transform 0.5s ease-in-out;
  text-align: center;
}

.navbar2 .menu-items li {
  margin-bottom: 1.2rem;
  font-size: 1.5rem;
  font-weight: 500;
}

.navbar2 .logo {
  position: absolute;
  top: 0px;
  left: 15px;
  font-size: 1.2rem;
  width: 150px;
  background-color: #000;
  height: 130px;
  /* color: #0e2431; */
}

@media only screen and (max-width: 840px) { 

  .navbar2 {
    display: unset;
  }
  .navbar2 .logo {
    width: 75px;
    height: 65px;
  }

  .navbar2 .menu-items {
    box-shadow: inset 0 0 50px #2C2C34;
  }

}

.nav-container input[type="checkbox"]:checked ~ .menu-items {
  transform: translateX(0);
}

.nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
  transform: rotate(45deg);
}

.nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
  transform: scaleY(0);
}

.nav-container input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
  transform: rotate(-45deg);
}

.nav-container input[type="checkbox"]:checked ~ .logo{
  display: none;
}